body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.application-busy-wrapper {
  display: block;
  z-index: 5001;
}

.application-busy-box-border-color {
  border: 2px solid #17a2b8 !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 50px;
  width: 50px;
  outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid rgb(8, 153, 201);
  background-image: none;
}

.border-info {
  border: 2px solid rgb(8, 153, 201);
}

.carousel-control-next-icon:after {
  content: ">";
  font-size: 30px;
  color: rgb(8, 153, 201);
}

.carousel-control-prev-icon:after {
  content: "<";
  font-size: 30px;
  color: rgb(8, 153, 201);
}

@media screen and (max-width: 600px) {
  .image-res {
    min-width: 50% !important;
  }

  #section-to-print {
    font-size: 12px !important;
  }
}

.poster-bg {
  background-image: linear-gradient(to right, #16a085, #f4d03f);
}

.mouse:hover {
  cursor: pointer;
}

.btn:focus {
  outline: none !important;
  box-shadow: none;
}